<template>
  <div></div>
</template>
<script>
/* eslint-disable no-console */
export default {
  name: "login",
  data() {
    return {};
  },
};
</script>
<style>
</style>

<template>
  <div class="table-responsive" style="margin-top: 40px;">
    <table class="table table-bordered table-striped" style="margin: auto; width: 90%;">
      <thead>
        <tr>
          <th scope="col" style="text-align: center;">No.</th>
          <th colspan="6" style="text-align: center;">Volunteer Application</th>
          <th colspan="4" style="text-align: center;">Clinical Investigator Registry</th>
          <th colspan="5" style="text-align: center;">Contact Us</th>
        </tr>
        <tr>
          <th></th>
          <th>Name</th>
          <th>Email</th>
          <th>Phone</th>
          <th>Height</th>
          <th>Weight</th>
          <th>Address</th>
          <th>Name</th>
          <th>Email</th>
          <th>Phone</th>
          <th>Adress</th>
          <th>Name</th>
          <th>Email</th>
          <th>Phone</th>
          <th>Subject</th>
          <th>Message</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in users" :key="user.id">
          <th scope="row">{{ user.id }}</th>
          <td>{{ user.name }}</td>
          <td>{{ user.email }}</td>
          <td>{{ user.phone }}</td>
          <td>{{ user.height }}</td>
          <td>{{ user.weight }}</td>
          <td>{{ user.adress }}</td>
          <td>{{ user.name }}</td>
          <td>{{ user.email }}</td>
          <td>{{ user.phone }}</td>
          <td>{{ user.adress }}</td>
          <td>{{ user.name }}</td>
          <td>{{ user.email }}</td>
          <td>{{ user.phone }}</td>
          <td>{{ user.subject }}</td>
          <td>{{ user.adress }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'showDataTables',
  data() {
    return {
      users: [
        { id: 1, name: 'John', email: '@johndoe', phone: '12345', height: '4', weight: '30', adress:'bpp', subject: '1' },
        { id: 2, name: 'Jane', email: '@janedoe', phone: '67892', height: '5', weight: '40', adress: 'kpp', subject: '2' },
        { id: 3, name: 'Peter', email: '@spiderman', phone: '456932', height: '6', weight: '50', adress: 'mpp', subject: '3' },
      ],
    };
  },
};
</script>
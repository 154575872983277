<template>
  <div>
    <div style="clear: both;height: 130px;">
      <div class="usagereagentdoc-header">{{ "Auditlogs - " + title }}</div>
    </div>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <div class="card shadow">
            <div class="card-body">
              <div style="padding-bottom: 10px;">
                <span>Note: Once a step is executed and signed-off in a batch document, press the save button to record the use log for the tagged reagents</span>
              </div>
              <div class="table-responsive">
                <base-table
                  class="table align-items-center table-flush"
                  :thead-classes="1 === 1 ? 'table-equ': 'table-rea'"
                  tbody-classes="list"
                  :data="tableData"
                >
                  <template slot="columns">
                    <th>Title</th>
                    <th>Batch Number</th>
                    <th>Step No</th>
                    <th>Executed By</th>
                    <th>Executed Date</th>
                  </template>

                  <template slot-scope="{row}">
                    <td>
                      <div class="avatar-group">
                        <span class="status" v-html="row.title"></span>
                      </div>
                    </td>
                    <td>
                      <div class="avatar-group">
                        <span class="status">{{row.batchNumber}}</span>
                      </div>
                    </td>
                    <td>
                      <div class="avatar-group">
                        <span class="status">{{row.stepNo}}</span>
                      </div>
                    </td>
                    <td>
                      <div class="avatar-group">
                        <span class="status">{{row.executedBy}}</span>
                      </div>
                    </td>
                    <td>
                      <div class="avatar-group">
                        <span class="status">{{formatDate(row.executedTime)}}</span>
                      </div>
                    </td>
                  </template>
                </base-table>
              </div>
              <div
                class="card-footer d-flex justify-content-end"
                :class="type === 'dark' ? 'bg-transparent': ''"
              >
                <base-pagination :per-page="5" :total="tableData.length"></base-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-console */
import Vue from "vue";
import VueClipboard from "vue-clipboard2";
import BTooltipDirective from "bootstrap-vue/es/directives/tooltip";

import axios from "../../utils/axiosinstance";
// import documentList from "./../../../sample-data/document-list.json";

Vue.use(VueClipboard);
export default {
  // props: [ 'reagentID' , 'title', 'type' ],
  props: [ 'type' ],
  directives: {
    "b-tooltip": BTooltipDirective
  },
  data() {
    return {
      tableData: [],
    };
  },
  beforeMount() {
    this.title = this.$route.query.title;
    this.reagentID = this.$route.query.reagentID;
  },
  mounted() {
    this.loadDocument();
  },
  methods: {
    addNew(){
      let app = this;
      app.$router.push({
          name: "newReagent",  
        });
    },
    loadDocument()
    {
      let authCookieObj = this.getCookie("auth-token");
      //console.log(authCookieObj);
      // let authObj = JSON.parse(authCookieObj.substring(2));
      let authObj;
      if( authCookieObj.startsWith("j:"))
      {
        authObj = JSON.parse(decodeURIComponent(this.getCookie("auth-token").substring(2).split(';')[0]));
      }
      else{
      authObj = JSON.parse(decodeURIComponent(authCookieObj.split(';')[0]));
      }
      var docListURL = "/api/organizations/" + authObj.orgs[0].orgID + "/usagelog?reagentID=" + this.reagentID;
      axios
        .get(docListURL)
        .then(response => {
          console.log(response);
          this.tableData = response.data.items;
        })
        .catch(reason => {
          console.log(reason);
          this.tableData = [];
        });
    },
    handleRowClick(item) {
      console.log(item);
      let app = this;
      
        app.$router.push({
          name: "newReagent",  
          params: { reagentID: item.reagentID }
        });
      
      // console.dir(e, item);
    },
    formatDate(strDate) {
      if (!strDate) return "";
      let date = new Date(strDate);
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return (
        date.getMonth() +
        1 +
        "/" +
        date.getDate() +
        "/" +
        date.getFullYear() +
        " " +
        strTime
      );
    },
    getCookie(name) {
      var dc = document.cookie;
      var prefix = name + "=";
      var begin = dc.indexOf("; " + prefix);
      if (begin == -1) {
        begin = dc.indexOf(prefix);
        if (begin != 0) return null;
      } else {
        begin += 2;
        var end = document.cookie.indexOf(";", begin);
        if (end == -1) {
          end = dc.length;
        }
      }
      return decodeURIComponent(
        decodeURI(dc.substring(begin + prefix.length, end))
      );
    }
    // onCopy() {
    //   this.$notify({
    //     type: "success",
    //     title: "Copied to clipboard"
    //   });
    // }
  }
};
</script>
<style>
.sharedHeader
{
  width: 200px;
}
.sharedRow
{
  display: inline-block;
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.table-equ{
  background-color: lightgreen;
  font-size: 14px;
}
.table-rea{
  background-color: lightpink;
  font-size: 14px;
}
.usagereagentdoc-header {
  font-weight: 700;
  text-align: center;
  /* position: relative; */
  margin-left: 40px;
  background-color: #ffff;
  margin-right: 95px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 0.375rem;
  width: calc(100% - 270px);
}
</style>

<template>
  <div>
    <div style="clear: both; height: 270px">
      <flowchart-header
        :docStatusType="docStatusType"
        :isFlowchart="isFlowchart"
      ></flowchart-header>
      <div class="container-fluid">
        <div class="row">
        <div class="flowChartbatch-header" v-if="flowChartTemp.title">
          {{ stripHTML(flowChartTemp.title) + " - " + flowChartTemp.batchNumber }}
        </div>
    </div>
      <button class="btn-default1 btn-sm btn-step-action1 view-batch-data"
        v-if="flowChartTemp && flowChartTemp.notes && showViewBatchDataButton()"
        @click="viewList()"
        >View Batch Data</button>
        <div class="col editControlParent"></div>
        <div class="col editorNewBatch">
          <div class="card shadow3">
            <div class="contacts"></div>
            <div class="card-body">
              <div
                v-if="flowChartTemp.title"
                v-html="flowChartTemp.title"
                class="form-control-alternative"
                v-on:click="handleOverlaysClick"
              ></div>
              <div v-if="!flowChartTemp.title">
                <div
                  :id="getId()"
                  contenteditable="true"
                  class="ckeditor-x form-control-alternative"
                  @focus="registerEditor($event)"
                  v-on:click="handleOverlaysEdit"
                ></div>
              </div>
            <div id="flowvue">
                <div class="float-buttons">
                  <button type="button" style="margin: 3px;" @click="$refs.chart.save()">Save</button>
                </div>
                <div v-if="flowChartTemp.nodes"
                  class="step-action-btn-group"
                  style="
                    float: right;
                    padding-left: 2px;
                    margin-left: 5px;
                    color: #2dce89;
                  "
                    >
                      <div style="float: right">
                        <label for="checkbox" style="font-size: 14px"
                          >Executed</label
                        >
                        <input
                          type="checkbox"
                          id="checkbox"
                          style="margin-left: 5px"
                          v-model="flowChartTemp.showingEsignatures.executed"
                          :disabled="flowChartTemp.showingEsignatures.executed"
                          @click="executeStep($event, nodes)"
                        />
                        <span class="user-audit" v-if="flowChartTemp.showingEsignatures.executed">{{
                          flowChartTemp.showingEsignatures.executedBy +
                          " - on " +
                          formatDate(flowChartTemp.showingEsignatures.executedTime)
                        }}</span
                        ><br />
                        <label
                          for="checkbox"
                          style="margin-left: 5px; font-size: 14px"
                          >Verified</label
                        >
                        <input
                          type="checkbox"
                          id="checkbox"
                          style="margin-left: 5px"
                          v-model="flowChartTemp.showingApproved.approved"
                          :disabled="flowChartTemp.showingApproved.approved"
                          @click="approveStep($event, nodes)"
                        />
                        <span class="user-audit" v-if="flowChartTemp.showingApproved.approved">{{
                          flowChartTemp.showingApproved.approveBy +
                          " - on " +
                          formatDate(flowChartTemp.showingApproved.approvedTime)
                        }}</span
                        ><br />
                      </div>
                </div>
                <flow-chart
                  :nodes="nodes"
                  :connections="connections"
                  @editnode="handleEditNode"
                  @editconnection="handleEditConnection"
                  @save="handleChartSave"
                  ref="chart"
                ></flow-chart>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col editRightParent"
          v-if="this.flowChartTemp.status1 != 'archived'"
        >
          <div id="editorControlList">
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <button class="btn btn-info" @click="saveFlowChart()">SAVE</button>
            </div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
            </div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
            </div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <a href="#!" class="btn btn-info" style="display: none">SHARE</a>
            </div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
            </div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
              <button class="btn btn-info" @click="archiveBatch($event)">
                FINISH
              </button>
            </div>
            <div
              class="contact ck-overlay editRightbtn"
              data-contact="0"
              data-name="time"
              data-class-name="time-input"
              draggable="true"
              tabindex="0"
            >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showImageBatchModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog share-modal">
              <div class="modal-content">
                <div class="modal-header">
                  {{ "Upload Image" }}
                  <button
                    type="button"
                    class="close"
                    @click="showImageBatchModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <span>Upload Image {{ ImageVarName }}:</span>
                  <input
                    type="file"
                    id="file"
                    style="margin-left: 36px; margin-bottom: 10px"
                    ref="file"
                    name="file-to-upload"
                    v-on:change="handleFileUploads()"
                  />
                  <base-button
                    size="sm"
                    type="default"
                    style="margin-bottom: 10px"
                    class="float-right1"
                    @click="addImage($event)"
                    >Add Image</base-button
                  >
                  <p v-if="filespath">
                    <img
                      style="width: 100px; height: 100px; margin-bottom: 10px"
                      :src="filespath"
                      alt=""
                    />
                  </p>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="previewImage($event)"
                  >
                    Upload
                  </button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showImageBatchModal = false"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- End: Image Upload Document -->

    <!-- Begin: QR Image Upload Batch Document -->
    <div v-if="showQRImageBatchModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog share-modal">
              <div class="modal-content">
                <div class="modal-header">
                  {{ "Upload QR Code" }}
                  <button
                    type="button"
                    class="close"
                    @click="showQRImageBatchModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <span>Upload QR Code {{ QRImageVarName }}:</span>
                  <p class="error">{{ error }}</p>
                  <qrcode-stream
                    style="width: 70%"
                    :camera="camera"
                    @decode="onDecode"
                    @init="onInit"
                  ></qrcode-stream>
                  <p class="decode-result">
                    Result: <b>{{ result }}</b>
                  </p>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="previewQRcode($event)"
                  >
                    Upload
                  </button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showQRImageBatchModal = false"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- End: QR Image Upload Document -->

    <!-- Begin: Video Image Upload Batch Document -->
    <div v-if="showVideoBatchModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog share-modal">
              <div class="modal-content">
                <div class="modal-header">
                  {{ "Upload Video" }}
                  <button
                    type="button"
                    class="close"
                    @click="showVideoBatchModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <span>Upload Video {{ VideoVarName }}:</span>
                  <input
                    type="file"
                    id="filevideo"
                    style="margin-left: 36px; margin-bottom: 10px"
                    ref="filevideo"
                    name="file-to-upload"
                    v-on:change="handleFileVideoUploads()"
                  />
                  <base-button
                    size="sm"
                    type="default"
                    style="margin-bottom: 10px"
                    class="float-right1"
                    @click="addVideo($event)"
                    >Add Video</base-button
                  >

                  <p v-if="videopath">
                    <video width="240" height="240" controls>
                      <source :src="videopath" :type="videotype" />
                      Your browser does not support the video tag.
                    </video>
                  </p>

                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="previewVideo($event)"
                  >
                    Upload
                  </button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showVideoBatchModal = false"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- End: Video Image Upload Document -->

    <!-- Begin: Data Tred Batch Document -->
    <div class="overlay-modal" v-if="showDataTredBatchModal"  style="text-align: center">
      <button class="btn-default1 btn-sm btn-step-action1" @click="planetData()">View Graph</button>
      <canvas id="planet-chart"></canvas>

    <div v-if="showDataTredBatchModal">
      <transition name="modal">
        <div class="modal-mask-Batch">
          <div class="modal-wrapper-Batch">
            <div class="modal-dialog share-modal">
              <div class="modal-content">
                <div class="modal-header">
                  {{ "Data Trending" }}
                  <button
                    type="button"
                    class="close"
                    @click="showDataTredBatchModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <span>Data Trending</span>

                  <div class="table-responsive">
                    <base-table
                      class="table align-items-center table-flush"
                      tbody-classes="list"
                      :data="documentdataTred"
                    >
                      <template slot="columns">
                        <th>Batch No</th>
                        <th>Variable Name</th>
                        <th>Value</th>
                      </template>

                      <template slot-scope="{ row }">
                        <td>
                          <div class="avatar-group">
                            <span class="status">{{ row.batchno }}</span>
                          </div>
                        </td>
                        <td>
                          <div class="avatar-group">
                            <span class="status">{{ row.varname }}</span>
                          </div>
                        </td>
                        <td>
                          <div class="avatar-group">
                            <span class="status">{{ row.varvalue }}</span>
                          </div>
                        </td>
                      </template>
                    </base-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

    </div>
    <!-- End: Data Tred Document -->

    <!-- Begin: Cross Reference Batch Document -->
    <div v-if="showCrossRefBatchModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog share-modal">
              <div class="modal-content">
                <div class="modal-header">
                  {{ "Cross Reference" }}
                  <button
                    type="button"
                    class="close"
                    @click="showCrossRefBatchModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <label
                    class="form-control-label"
                    v-html="documentcrossRef"
                  ></label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- End: Cross Reference Document -->

    <!-- skilltrend -->
    <div class="overlay-modal graph-button" v-if="showSkillTredBatchModal" style="text-align: center">
      <button class="btn-default1 btn-sm btn-step-action1" @click="skillData()">Executed Graph</button>
      <button class="btn-default1 btn-sm btn-step-action1" @click="verifiedSkillData()">Verified Graph</button>
      <canvas id="planet-chart"></canvas>
    
      <div v-if="showSkillTredBatchModal">
          <transition name="modal">
            <div class="modal-mask-Batch">
              <div class="modal-wrapper-Batch">
                <div class="modal-dialog share-modal">
                  <div class="modal-content">
                    <div class="modal-header">
                      {{ "Skill Trending" }}
                      <button
                        type="button"
                        class="close"
                        @click="showSkillTredBatchModal=false">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
                      <span>Skill Trending</span>
                        
                      <div class="table-responsive">
                          <base-table
                            class="table align-items-center table-flush"
                            tbody-classes="list"
                            :data="flowChartSkill"
                          >
                            <template slot="columns">
                              <th>Batch No</th>
                              <th>Executed User</th>
                              <th>Verified User</th>
                            </template>
                            <template slot-scope="{row}">
                              <td>
                                <div class="avatar-group">
                                  <span class="status">{{row.batchno}}</span>
                                </div>
                              </td>
                            <td>
                                <div class="avatar-group" v-for="(executeduser, executeduser_index) in row.executedUsers" :key="executeduser_index">
                                  <span class="status">{{executeduser.executeduser}}</span>
                                </div>
                              </td>
                              <td>
                                <div class="avatar-group" v-for="(verifieduser, verifieduser_index) in row.verifiedUsers" :key="verifieduser_index">
                                  <span class="status">{{verifieduser.verifieduser}}</span>
                                </div>
                              </td>
                            </template>
                          </base-table>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
      </div>
    </div>
    <!-- end skill trend -->

    <div v-if="showViewListBatchModal">
        <transition name="modal">
          <div class="modal-mask-Batch view-flowchart-data">
            <div class="modal-wrapper-Batch">
              <div class="modal-dialog share-modal">
                <div class="modal-content">
                  <div class="modal-header">
                    {{ "View list of data-inputs and formula" }}
                    <button
                      type="button"
                      class="close"
                      @click="showViewListBatchModal=false">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div class="table-responsive">
                        <base-table
                          class="table align-items-center table-flush formula-datainput-table-list"
                          tbody-classes="list"
                          :data="documentviewList"
                        >
                          <template slot="columns">
                            <th>Step No</th>
                            <th>Data Label</th>
                            <th>Data</th>
                          </template>
                          <template slot-scope="{row}" v-if="row.type">
                            <td> {{row.stepno}} </td>
                            <td> {{row.datalabel}} </td>
                            <td> {{row.data}} </td>
                          </template>
                        </base-table>
                    </div>
                    <!-- <i class="fa fa-download"></i> -->
                    <button @click="tableToCSV()"
                    class="btn float-right btn-default btn-sm m-2">
                    Export as CSV</button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showViewListBatchModal=false"
                  >Cancel</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
    </div>

    <!-- start : edit flow chart modal -->
    <div v-if="showFlowchartModal">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  Modify Nodes Name
                  <button
                    type="button"
                    class="close"
                    @click="showFlowchartModal = false"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div slot="header">
                    <label for="name">Name</label>
                    <input id="name" class="form-control" v-model="editInput.name"/>
                    <label for="name">Type</label>
                    <select class="form-control" id="type" v-model="editInput.type">
                    <option :key="'node-type-' + item.id" :value="item.id"
                            v-for="item in [ { name: 'Start', id: 'start' }, { name: 'End', id: 'end' }, { name: 'Operation', id: 'operation' } ]"
                    >
                        {{item.name}}
                    </option>
                    </select>
                  </div>
                  <br />
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="handleClickSaveConnection"
                  >
                    Ok
                  </button>
                  <button
                    class="btn float-right btn-default btn-sm m-2"
                    @click="showFlowchartModal = false"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>

  </div>
</template>
<script>
/* eslint-disable no-console */
import axios from "../../../utils/axiosinstance";

// import Vue from "vue";
import { v4 as uuidv4 } from "uuid";
import flowchartHeader from "./flowchartHeader.vue";
import { QrcodeStream } from "vue-qrcode-reader";
import Chart from 'chart.js';
import FlowChart from "flowchart-vue";

export default {
  components: { flowchartHeader, QrcodeStream, FlowChart },
  data() {
    return {
      nodes: [
        { id: 1, x: 140, y: 270, name: "Start", type: "start" },
        {
          id: 2,
          x: 540,
          y: 270,
          name: "End",
          type: "end",
        },
        {
          id: 3,
          x: 340,
          y: 270,
          name: "Operation",
          type: "operation",
          description: "I'm here",
        },
        {
          id: 4,
          x: 340,
          y: 360,
          name: "Operation",
          type: "operation",
          description: "I'm here",
        },
      ],
      connections: [
        {
          source: { id: 1, position: "right" },
          destination: { id: 3, position: "left" },
          id: 1,
          type: "pass",
        },
        {
          source: { id: 1, position: "right" },
          destination: { id: 4, position: "left" },
          id: 1,
          type: "pass",
        },
        {
          source: { id: 3, position: "right" },
          destination: { id: 2, position: "left" },
          id: 2,
          type: "pass",
        },
        {
          source: { id: 4, position: "right" },
          destination: { id: 2, position: "left" },
          id: 2,
          type: "pass",
        },
      ],
      showingEsignatures: {},
      showingApproved: {},
      editInput: '',
      showFlowchartModal: false,
      layout: [
                { x: 0, y: 0, w: 2, h: 2, i: "0", uid: uuidv4(), },
            ],
      draggable: true,
      resizable: true,
      colNum: 12,
        notes: [],
        batchID: "",
        isFlowchart: "",
        docStatusType: "",
        flowChartTemp: {},
        documentdataTred: [],
        documentcrossRef: "",
        temptag: [],
        tagjson: {},
        oldstring: [],
        tempstep: null,
        authCookie: null,
        rowData: [],
        inputRow: {},
        gridOptions: null,
        documentviewList: [],
        flowChartSkill: [],
        timermm: "00",
        timerss: "00",
        timerStart: false,
        spanVisible: false,
        showImageBatchModal: false,
        showDataTredBatchModal: false,
        showCrossRefBatchModal: false,
        showSkillTredBatchModal: false,
        showViewListBatchModal: false,
        showCanvas: false,
        VideoVarName: "",
        videopath: "",
        videotype: "",
        videopathold: "",
        videotypeold: "",
        showVideoBatchModal: false,
        ImageVarName: "",
        ImageSrc: "",
        showQRImageBatchModal: false,
        QRImageVarName: "",
        QRImageSrc: "",
        result: "",
        oldString: "Upload QR Code",
        error: "",
        camera: "auto",
        files: "",
        filespath: "",
        updateSecId: "",
        updateStepId: "",
    };
    },
  beforeMount() {
    this.batchID = this.$route.query.batchID;
    this.docStatusType = this.$route.query.docStatusType;
    this.isFlowchart = this.$route.query.isFlowchart;
  },
  mounted() {
    if (this.batchID) {
      axios
        .get(
          "/api/flowChartBatch/organizations/" +
            this.authCookie.orgs[0].orgID +
            "/" +
            this.batchID
        )
        .then((response) => {
          console.log(response);
          this.flowChartTemp = response.data.item;
          this.flowChartTemp.title = this.flowChartTemp.title
            .replace(/(<([^>]+)>)/gi, "")
            .replace(/[\r\n]/g, "");
          this.usagelogtagTemp = {};
          this.nodes = response.data.item.nodes;
          this.connections = response.data.item.connections;
          if(!this.flowChartTemp.showingEsignatures){
            this.flowChartTemp.showingEsignatures = {};
          }
          if(!this.flowChartTemp.showingApproved){
            this.flowChartTemp.showingApproved = {};
          }
          //steplock
          this.steplock();
        })
        .catch((reason) => {
          console.log(reason);
          this.flowChartTemp = [];
          this.usagelogtagTemp = {};
        });
    }
  },
  created() {
    let authCookieObj = this.getCookie("auth-token");
    // this.authCookie = JSON.parse(authCookieObj.substring(2));
    let authObj;
    if (authCookieObj.startsWith("j:")) {
      authObj = JSON.parse(
        decodeURIComponent(
          this.getCookie("auth-token").substring(2).split(";")[0]
        )
      );
    } else {
      authObj = JSON.parse(decodeURIComponent(authCookieObj.split(";")[0]));
    }
    this.authCookie = authObj;
  },
  beforeCreate() {
    //let recaptchaScript = document.createElement("script");
    //recaptchaScript.setAttribute("src", "ckeditor.js");
    //document.head.appendChild(recaptchaScript);
  },
  methods: {
    executeStep($event, nodes) {
      console.log(nodes);
      this.$set(this.flowChartTemp.showingEsignatures, "executed", true);
      this.$set(this.flowChartTemp.showingEsignatures, "executedBy", this.authCookie.userName);
      this.$set(this.flowChartTemp.showingEsignatures, "executedByOrg", this.authCookie.orgs[0].orgID);
      this.$set(this.flowChartTemp.showingEsignatures, "executedTime", new Date());
    },
    approveStep($event, nodes) {
      console.log(nodes);
      this.$set(this.flowChartTemp.showingApproved, "approved", true);
      this.$set(this.flowChartTemp.showingApproved, "approveBy", this.authCookie.userName);
      this.$set(this.flowChartTemp.showingApproved, "approvedByOrg", this.authCookie.orgs[0].orgID);
      this.$set(this.flowChartTemp.showingApproved, "approvedTime", new Date());
    },
    handleChartSave(nodes, connections) {
      
      if(this.flowChartTemp.showingEsignatures.executed){
        const valuesExecuted = {
        executed: "true",
        executedBy: this.authCookie.userName,
        executedByOrg: this.authCookie.orgs[0].orgID,
        executedTime: new Date(),
      }
      this.showingEsignatures = valuesExecuted;
      this.flowChartTemp.showingEsignatures = this.showingEsignatures;
      }
      else if(this.flowChartTemp.showingApproved.approved){
      const valuesApproved = {
        approved: "true",
        approveBy: this.authCookie.userName,
        approvedByOrg: this.authCookie.orgs[0].orgID,
        approvedTime: new Date(),
      }
      this.showingApproved = valuesApproved;
      this.flowChartTemp.showingApproved = this.showingApproved;
      }
      this.flowChartTemp.nodes = nodes;
      this.flowChartTemp.connections = connections;
      alert('node added successfully');
    },
    handleEditNode(node) {
      console.log(node.description);
      this.showFlowchartModal = true;
      this.editInput = node;
    },
    handleEditConnection(connection) {
      console.log(connection);
    },
    handleClickSaveConnection() {
      for(var i=0; i<this.$refs.chart.nodes.length; i++){
        if(this.$refs.chart.nodes[i].id == this.editInput.id){
        this.$refs.chart.nodes[i].name = this.editInput.name;
        this.$refs.chart.nodes[i].type = this.editInput.type;
        }
      }
      this.showFlowchartModal = false;
    },
    async onDecode(content) {
      this.result = content;
      this.turnCameraOff();
      await this.timeout(3000);
    },
    timeout(ms) {
      return new Promise((resolve) => {
        window.setTimeout(resolve, ms);
      });
    },
    turnCameraOff() {
      this.camera = "off";
    },
    turnCameraOn() {
      this.camera = "auto";
    },
    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === "NotAllowedError") {
          this.error = "ERROR: you need to grant camera access permisson";
        } else if (error.name === "NotFoundError") {
          this.error = "ERROR: no camera on this device";
        } else if (error.name === "NotSupportedError") {
          this.error = "ERROR: secure context required (HTTPS, localhost)";
        } else if (error.name === "NotReadableError") {
          this.error = "ERROR: is the camera already in use?";
        } else if (error.name === "OverconstrainedError") {
          this.error = "ERROR: installed cameras are not suitable";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.error = "ERROR: Stream API is not supported in this browser";
        }
      }
    },
    crossref(e, note) {
      console.log(e);
      console.log(note);
      this.documentcrossRef = "No Data Found";
      var dom = document.createElement("div");
      dom.innerHTML = note.description;
      var atag = dom.getElementsByTagName("a");
      if (atag != undefined && atag.length > 0) {
        var ki;
        for (ki = 0; ki < atag.length; ki++) {
          if (
            atag[ki].innerHTML
              .toString()
              .indexOf("ross Reference / Document Title =", 0) > 0
          ) {
            var altarray = atag[ki].innerHTML.toString().split("/");
            if (altarray != undefined && altarray.length > 0) {
              var DocTitle = altarray[1].split("=")[1].toString().trim();
              var secid = altarray[2].split("=")[1].toString().trim();
              var stepid = altarray[3].split("=")[1].toString().trim();
              console.log(DocTitle);
              secid = secid - 1;
              stepid = stepid.split(".")[1] - 1;
              console.log(secid);
              console.log(stepid);
            }

            axios
              .get(
                "/api/organizations/" +
                  this.authCookie.orgs[0].orgID +
                  "/crossref/" +
                  DocTitle
              )
              .then((response) => {
                this.documentcrossRef =
                  response.data.item.notes[secid].description;
                console.log(this.documentcrossRef);
                this.showCrossRefBatchModal = true;
              })
              .catch((reason) => {
                console.log(reason);
                this.documentcrossRef = "No Data Found";
              });
          }
        }
      }
    },
    UploadVideo(e, note) {
      console.log(e);
      console.log(note);
      this.showVideoBatchModal = true;
      var stepBody = event.target.closest('div[class^="step-body"]');
      this.updateSecId = stepBody.dataset.secid;
      this.updateStepId = stepBody.id;
    },
    UploadQR(e, note) {
      console.log(e);
      console.log(note);
      this.showQRImageBatchModal = true;
      var stepBody = event.target.closest('div[class^="step-body"]');
      this.updateSecId = stepBody.dataset.secid;
      this.updateStepId = stepBody.id;
      this.tempstep = note;
      var dom = document.createElement("div");
      dom.innerHTML = note.description;
      var imgtag = dom.getElementsByTagName("img");
      if (imgtag != undefined && imgtag.length > 0) {
        var ki;
        for (ki = 0; ki < imgtag.length; ki++) {
          var imgtagalt = imgtag[ki].getAttribute("alt");
          var altarray = imgtagalt.split("_");
          var varName = null;
          if (altarray != undefined && altarray.length > 0) {
            if (altarray[0] == "qrImage") {
              varName = altarray[1];
              this.QRImageVarName = varName;
              this.QRImageSrc = imgtagsrc;
              this.showQRImageBatchModal = true;
              this.turnCameraOn();
              this.result = "";
            }
          }
          var imgtagsrc = imgtag[ki].getAttribute("src");
          //console.log(imgtagalt);
        }
      }
    },
    UploadImg(e, note) {
      console.log(e);
      console.log(note);
      this.showImageBatchModal = true;
      var stepBody = event.target.closest('div[class^="step-body"]');
      this.updateSecId = stepBody.dataset.secid;
      this.updateStepId = stepBody.id;
    },
    handleFileUploads() {
      this.files = this.$refs.file.files[0];
      //console.log(this.files);
    },
    handleFileVideoUploads() {
      this.files = this.$refs.filevideo.files[0];
      //console.log(this.files);
    },
    addVideo(e) {
      console.log(e);
      let app = this;
      let formData = new FormData();
      formData.append("file", this.files);
      axios
        .post("/api/uploadVideosFiles", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          console.log(response);
          app.videopath = "";
          app.videotype = "";
          var path = response.data.fileUrl;
          app.videopath = path.toString();
          app.videotype = response.data.type.toString();
          console.log(app.videopath);
          console.log(app.videotype);
          alert("Video Uploaded Successfully");
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    addImage(e) {
      console.log(e);
      let app = this;
      let formData = new FormData();
      formData.append("file", this.files);
      axios
        .post("/api/uploadWidgetFiles", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          console.log(response);
          app.filespath = "";
          var path = response.data.fileUrl;
          app.filespath = path.toString();
          alert("File Uploaded Successfully");
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    previewImage(e) {
      console.dir(e);
        let app = this;
      var sec = app.flowChartTemp.notes.filter((x) => x.uid == app.updateSecId);
      if (sec && sec[0]) {
        // var step = sec[0].meta.filter((y) => y.uid == app.updateStepId);
        if (!sec[0].meta) {
          sec[0].meta = [];
        }
        for (var j = 0; j < sec[0].meta.length; j++) {
          if (sec[0].meta[j].mediaimagefound) {
            sec[0].meta[j].imagePath = app.filespath;
            sec[0].meta[j].description = sec[0].meta[j].description.toString().replace(sec[0].meta[j].description,sec[0].meta[j].imagePath);
            document.getElementById("upload-button").innerHTML = "";
            app.$set(sec[0], 0);
            app.showImageBatchModal = false;
          }
        }
        app.$set(sec[0], 0);
        }
      app.showImageBatchModal = false;
    },
    previewVideo(e) {
      console.dir(e);
        let app = this;
      var sec = app.flowChartTemp.notes.filter((x) => x.uid == app.updateSecId);
      if (sec && sec[0]) {
        // var step = sec[0].meta.filter((y) => y.uid == app.updateStepId);
        if (!sec[0].meta) {
          sec[0].meta = [];
        }
        for (var j = 0; j < sec[0].meta.length; j++) {
          if (sec[0].meta[j].videofound) {
            sec[0].meta[j].videoUrl = app.videopath;
            app.$set(sec[0], 0);
            app.showVideoBatchModal = false;
          }
        }
        app.$set(sec[0], 0);
        }
      app.showVideoBatchModal = false;
    },
    previewQRcode(e) {
      console.dir(e);
        let app = this;
      var sec = app.flowChartTemp.notes.filter((x) => x.uid == app.updateSecId);
      if (sec && sec[0]) {
        // var step = sec[0].meta.filter((y) => y.uid == app.updateStepId);
        if (!sec[0].meta) {
          sec[0].meta = [];
        }
        for (var j = 0; j < sec[0].meta.length; j++) {
          if (sec[0].meta[j].qrimagefound) {
            // sec[0].meta[j].videoUrl = app.videopath;
            app.$set(sec[0], 0);
            app.showQRImageBatchModal = false;
          }
        }
        app.$set(sec[0], 0);
        }
      app.showQRImageBatchModal = false;
    },
    formulaCalculate(e, note, meta) {
      console.log(e);
      for( var i = 0; i < note.meta.length; i++){
      if(note.meta[i].uid == meta.uid && note.meta[i].formulafound){
        var ResultValue = eval(note.meta[i].formula);
        this.$set(meta, "ResultText", "Result : " + note.meta[i].formula.toString() + " = " + ResultValue);
      }
      }
    },
    startTimer(e, note) {
      this.timerStart = true;
      this.spanVisible = true;
      this.timermm = 0;
      this.timerss = 0;
      this.$set(note.meta, "timermm", this.timermm);
      this.$set(note.meta, "timerss", this.timerss);
      this.$set(note.meta, "timerStart", this.timerStart);
      this.$set(note.meta, "spanVisible", this.spanVisible);
      console.log(e);
      //console.log("Start Timer");
      //console.log(meta);
      this.countDownTimer(note.meta);
    },
    stopTimer(e, note) {
      console.log(e);
      console.log(note);
      this.timerStart = false;
      note.meta.timerStart = false;
      //console.log(this.timermm + ' : ' + this.timerss);
      this.countDownTimer(note.meta);
    },
    countDownTimer(note) {
      //console.log('countDownTimer');
      //console.log(meta);
      //console.log(meta.timerStart);
      if (note.timerStart) {
        setTimeout(() => {
          note.timerss += 1;
          if (note.timerss == 60) {
            note.timerss = 0;
            note.timermm += 1;
          }
          this.countDownTimer(note);
        }, 1000);
      }
    },
    DataTrend(e, meta,note_index,meta_index) {
      console.log(e);
      console.log(note_index);
      console.log(meta);
      console.log(meta_index);
      console.log(this.batchID);
      axios
        .get(
          "/api/flowChartBatch/organizations/" +
            this.authCookie.orgs[0].orgID +
            "/datatred/" +
            this.batchID
        )
        .then((response) => {

          console.log(response);
          var temp = response.data.items;
          var temptable = {};
          var batchNo = "";
          this.documentdataTred = [];

          var i;
            for(i=0;i<temp.length;i++)
            {
              var j
              batchNo = temp[i].batchNumber;
              for(j=0;j<temp[i].notes[note_index].meta[meta_index].dataTrandings.length;j++)
              {
                temptable = {};
                temptable.batchno = batchNo;
                temptable.varname = temp[i].notes[note_index].meta[meta_index].dataTrandings[j].varname;
                temptable.varvalue = temp[i].notes[note_index].meta[meta_index].dataTrandings[j].varvalue;      
                this.$set(this.documentdataTred, this.documentdataTred.length, temptable);
              }

            }

          this.showDataTredBatchModal = true;
        })
        .catch((reason) => {
          console.log(reason);
          this.documentdataTred = [];
        });
    },
    setValue(e, note, meta) {
      console.dir(e);
      console.log(note);
      let app = this;
      var stepBody = event.target.closest('div[class^="step-body"]');
      this.updateSecId = stepBody.dataset.secid;
      this.updateStepId = stepBody.id;
      var sec = app.flowChartTemp.notes.filter((x) => x.uid == app.updateSecId);
      if (sec && sec[0]) {
        if (!sec[0].meta) {
          sec[0].meta = [];
        }
        var VarName = meta.description;
        var pValText = prompt("Please Enter Value for " + VarName + ":","");
        if (!pValText) return;
        if (meta.type == "data-input") {
              meta.value = pValText+"_____";
            }
            else if(meta.type == "dataNumberTranding" && meta.datatrendfound ){
              meta.value = pValText;
              if(meta.dataTrandings != undefined && meta.dataTrandings.length > 0)
              {
                var j;
                for(j=0;j<meta.dataTrandings.length;j++)
                {
                  if(meta.dataTrandings[j].varname == VarName)
                  {
                    meta.dataTrandings[j].varvalue = pValText;
                    meta.dataTrandings[j].batchno = this.flowChartTemp.batchNumber;
                  }
                }
              }
            }
            else if(meta.type == "dataNumberTranding" && !meta.datatrendfound ){
              meta.value = pValText;
            }
        app.$set(sec[0], 0);
        }
    },
    selectOption(meta){
      var pValText = prompt(" Enter selected option number only", "" );
      if (!pValText) return;
      meta.value += pValText + " ";
    },
    skillTrend(e, meta,note_index,meta_index)
    {
      console.log(e);
      console.log(meta);
      console.log(note_index);
      console.log(meta_index);
      console.log(this.batchID);
      axios
          .get(
          "/api/flowChartBatch/organizations/" +
            this.authCookie.orgs[0].orgID +
            "/datatred/" +
            this.batchID
          )
          .then(response => {
            console.log(response);
          var temp = response.data.items;
          var temptable = {};
          var batchNo = "";
          this.flowChartSkill = [];
              var i;
              for(i=0;i<temp.length;i++)
              {
                var j
                batchNo = temp[i].batchNumber;
                for(j=0;j<temp[i].notes[note_index].meta[meta_index].skills.length;j++){
                  temptable = {};
                  temptable.batchno = batchNo;
                  temptable.executedUsers = temp[i].notes[note_index].meta[meta_index].skills[j].executedUsers;
                  temptable.verifiedUsers = temp[i].notes[note_index].meta[meta_index].skills[j].verifiedUsers;
                  this.$set(this.flowChartSkill, this.flowChartSkill.length, temptable);
                  }
                  }
            this.showSkillTredBatchModal = true;
          })
          .catch(reason => {
            console.log(reason);
            this.flowChartSkill = [];
          });
    },
    skillData(){
      axios
        .get(
            "/api/flowChartBatch/organizations/" +
              this.authCookie.orgs[0].orgID +
              "/datatred/" +
              this.batchID
          )
        .then(response => {
          console.log(response);
          var skillTemp = response.data.items;
          const  tempExecuteObj = new Map();
          for(let i = 0; i < skillTemp.length; i++){
            for(let j = 0; j < skillTemp[i].notes.length; j++){
            for(let k = 0; k < skillTemp[i].notes[j].meta.length; k++){
              for(let l = 0; l < skillTemp[i].notes[j].meta[k].skills.length; l++){
                var executeduser = skillTemp[i].notes[j].meta[k].skills[l].executedUsers[0].executeduser;
                
                if(tempExecuteObj.get(executeduser)) {
                  let count = tempExecuteObj.get(executeduser);
                  tempExecuteObj.set(executeduser, ++count);
                } else {
                  tempExecuteObj.set(executeduser, 1);
                }                
              }
                }
              }
            }
                    this.showCanvas = true;
                    const ctx = document.getElementById('planet-chart');
                    new Chart(ctx, {
                    type: "bar",
                    data: {
                      labels: Array.from(tempExecuteObj.keys()),
                      datasets: [
                        {
                          label: "executedUser",
                          data: Array.from(tempExecuteObj.values()),         
                          borderColor: "#36495d",
                          borderWidth: 1,
                        },
                        
                      ]
                    },
                    options: {
                      responsive: true,
                      lineTension: 1,
                      scales: {
                        yAxes: [
                          {
                            ticks: {
                              beginAtZero: true,
                              padding: 25,
                              AutoRange: true,
                            },
                            scaleLabel: {
                              display: true,
                              labelString: 'Counts ------>'
                            }
                          }
                        ],
                        xAxes: [
                          {
                            ticks: {
                              padding: 25,
                            },
                            scaleLabel: {
                              display: true,
                              labelString: 'User Names ------>'
                            }
                          }
                        ]
                      }
                    }
                    });
        })
        .catch(reason => {
          console.log(reason);
          this.flowChartTemp = [];
        });
    },
    verifiedSkillData(){
      axios
        .get(
            "/api/flowChartBatch/organizations/" +
              this.authCookie.orgs[0].orgID +
              "/datatred/" +
              this.batchID
          )
        .then(response => {
          console.log(response);
          var skillTemp = response.data.items;
          const  tempVerifyObj = new Map();
          for(let i = 0; i < skillTemp.length; i++){
            for(let j = 0; j < skillTemp[i].notes.length; j++){
            for(let k = 0; k < skillTemp[i].notes[j].meta.length; k++){
              for(let l = 0; l < skillTemp[i].notes[j].meta[k].skills.length; l++){
                var verifieduser = skillTemp[i].notes[j].meta[k].skills[l].verifiedUsers[0].verifieduser;
                if(tempVerifyObj.get(verifieduser)) {
                  let count = tempVerifyObj.get(verifieduser);
                  tempVerifyObj.set(verifieduser, ++count);
                } else {
                  tempVerifyObj.set(verifieduser, 1);
                }
              }                
            }
            }
            }
                    this.showCanvas = true;
                    const ctx = document.getElementById('planet-chart');
                    new Chart(ctx, {
                    type: "bar",
                    data: {
                      labels: Array.from(tempVerifyObj.keys()),
                      datasets: [
                        {
                          label: "verifieduser",
                          data: Array.from(tempVerifyObj.values()),         
                          borderColor: "#36495d",
                          borderWidth: 1,
                        },
                        
                      ]
                    },
                    options: {
                      responsive: true,
                      lineTension: 1,
                      scales: {
                        yAxes: [
                          {
                            ticks: {
                              beginAtZero: true,
                              padding: 25,
                              AutoRange: true,
                            },
                            scaleLabel: {
                              display: true,
                              labelString: 'Counts ------>'
                            }
                          }
                        ],
                        xAxes: [
                          {
                            ticks: {
                              padding: 25,
                            },
                            scaleLabel: {
                              display: true,
                              labelString: 'User Names ------>'
                            }
                          }
                        ]
                      }
                    }
                    });
        })
        .catch(reason => {
          console.log(reason);
          this.flowChartTemp = [];
        });
    },
    planetData(){
      // console.log(flowChartTemp);
      axios
        .get(
          "/api/flowChartBatch/organizations/" +
            this.authCookie.orgs[0].orgID +
            "/" +
            this.batchID
        )
        .then(response => {
          console.log(response);
          var planetTemp = response.data.item;
          for(let i = 0; i < planetTemp.notes.length; i++){
            for(let j = 0; j < planetTemp.notes[i].meta.length; j++){
              if(planetTemp.notes[i].meta[j].dataTrandings){
              for(let k = 0; k < planetTemp.notes[i].meta[j].dataTrandings.length; k++){
                var variableBatchno = planetTemp.notes[i].meta[j].dataTrandings[k].batchno;
                var variableName = planetTemp.notes[i].meta[j].dataTrandings[k].varname;
                var variableValue = planetTemp.notes[i].meta[j].dataTrandings[k].varvalue;
                console.log(variableValue);
                this.showCanvas = true;
                    const ctx = document.getElementById('planet-chart');
                    new Chart(ctx, {
                    type: "line",
                    data: {
                      labels: [variableBatchno],
                      datasets: [
                        {
                          label: variableName,
                          data: [variableBatchno],            
                          borderColor: "#36495d",
                          borderWidth: 2,
                        },
                        
                      ]
                    },
                    options: {
                      responsive: true,
                      lineTension: 1,
                      scales: {
                        yAxes: [
                          {
                            ticks: {
                              beginAtZero: true,
                              padding: 25,
                              AutoRange: true,
                              
                            },
                            scaleLabel: {
                              display: true,
                              labelString: 'Values ------>'
                            }
                          }
                        ],
                        xAxes: [
                          {
                            ticks: {
                              padding: 25,
                            },
                            scaleLabel: {
                              display: true,
                              labelString: 'Batch Number ------>'
                            }
                          }
                        ]
                      }
                    }
                    });
                }
              }
            }
          }
        })
        .catch(reason => {
          console.log(reason);
          this.flowChartTemp = [];
        });
    },
    showViewBatchDataButton(){
    console.log(this.flowChartTemp);
    for( var i = 0; i < this.flowChartTemp.notes.length; i++) {
      for( var j = 0; j < this.flowChartTemp.notes[i].meta.length; j++ ) {
          console.log(this.flowChartTemp.notes[i].meta[j].formulafound);
          if(this.flowChartTemp.notes[i].meta[j].formulafound || this.flowChartTemp.notes[i].meta[j].datatrendfound || this.flowChartTemp.notes[i].meta[j].inputfound){
          return this.flowChartTemp.notes[i].meta[j].formulafound || this.flowChartTemp.notes[i].meta[j].datatrendfound || this.flowChartTemp.notes[i].meta[j].inputfound;
          }
      }
    }
    return false;
    },
    viewList()
    {
      axios
          .get(
            "/api/flowChartBatch/organizations/" +
              this.authCookie.orgs[0].orgID +
              "/datatred/" +
              this.batchID
          )
          .then(response => {
            var temp = response.data.items;
            var temptable = {};
            this.documentviewList = [];
              for(var i=0;i<temp.length;i++){
                for(var j=0;j<temp[i].notes.length;j++){
                for(var k=0;k<temp[i].notes[j].meta.length;k++){
                  temptable = {};
                  if(temp[i].notes[j].meta[k].formulafound){
                  var labelText = temp[i].notes[j].meta[k].formuladatalabel;
                  var formulaResult = temp[i].notes[j].meta[k].ResultText.split(':')[1].split('=')[1];
                  var formula = temp[i].notes[j].meta[k].formulafound;
                  temptable.stepno =  j+1 + "." + (k+1);
                  temptable.datalabel = labelText;
                  temptable.data = formulaResult;
                  temptable.type = formula;
                  }
                  else if(temp[i].notes[j].meta[k].inputfound || temp[i].notes[j].meta[k].datatrendfound){
                    var inputLabel = temp[i].notes[j].meta[k].description;
                    var inputData ;
                    if(temp[i].notes[j].meta[k].inputfound){
                      inputData = temp[i].notes[j].meta[k].value.split('_')[0];
                    }
                    if(temp[i].notes[j].meta[k].datatrendfound){
                      inputData = temp[i].notes[j].meta[k].value;
                    }
                    var inputdata = temp[i].notes[j].meta[k].inputfound || temp[i].notes[j].meta[k].datatrendfound;
                    temptable.stepno =  j+1 + "." + (k+1);
                    temptable.datalabel = inputLabel;
                    temptable.data = inputData;
                    temptable.type = inputdata;
                  }
                  if (temptable && temptable.stepno) {
                  this.$set(this.documentviewList, this.documentviewList.length, temptable);
                  }
                  }              
                }
              }          
            this.showViewListBatchModal = true;
          })
          .catch(reason => {
            console.log(reason);
            this.documentviewList = [];
          });

    },
    tableToCSV(){
    let csv_data = [];
    let rows = document.getElementsByTagName('tr');
    for (let i = 0; i < rows.length; i++) {
        let cols = rows[i].querySelectorAll('td,th');
        let csvrow = [];
        for (let j = 0; j < cols.length; j++) {
            csvrow.push(cols[j].innerHTML);
        }
        csv_data.push(csvrow.join(","));
    }
    csv_data = csv_data.join('\n');
    this.downloadCSVFile(csv_data);
    },
    downloadCSVFile(csv_data) {
      var CSVFile = {};
      CSVFile = new Blob([csv_data], {
          type: "text/csv"
      });
        let temp_link = document.createElement('a');
      temp_link.download = "GfG.csv";
      let url = window.URL.createObjectURL(CSVFile);
      temp_link.href = url;
      temp_link.style.display = "none";
      document.body.appendChild(temp_link);
      temp_link.click();
      document.body.removeChild(temp_link);
    },
    addRows(e, note, meta){
      console.dir(e);
      console.log(note);
      // var x = document.createElement("INPUT");
      //         x.setAttribute("type", "text");
      //         x.setAttribute("name", "textInput");
      //         x.setAttribute("value", "");
      //         document.getElementById("myForm").appendChild(x);
      let app = this;
      var data = {};
          for(var k = 0; k < meta.columnDefs.length; k++){
          if (meta.columnDefs[k].field) {
              data[meta.columnDefs[k].field] = '';
          }
        }
        meta.rowData.push(data);
        app.$set(note, 0);
    },
    notApplicablenote(e, note) {
      this.$set(note, "notApplicable", true);
      this.$set(note, "notApplicableBy", this.authCookie.userName);
      this.$set(note, "notApplicableByOrg", this.authCookie.orgs[0].orgID);
      this.$set(note, "notApplicableTime", new Date());
    },
    onChangeExecute(note, note_index) {
    console.log(note);
    console.log(note_index);
    axios
        .get(
          "/api/flowChartBatch/organizations/" +
            this.authCookie.orgs[0].orgID +
            "/datatred/" +
            this.batchID
        )
        .then(response => {
          console.log(response);
          var temp = response.data.items;
          var batchNo = '';
          var temptable = {};
          this.flowChartSkill = [];
            var i;
            for(i=0;i<temp.length;i++)
            {
              batchNo = temp[i].batchNumber;
                for(var j=0;j<temp[i].notes[note_index].meta.length;j++){
                  for(var k=0;k<temp[i].notes[note_index].meta[j].skills.length;k++){
                  temptable = {};
                  if (this.flowChartTemp.batchNumber == batchNo) {
                    var executedUser = {};
                    executedUser.executeduser = this.authCookie.userName;
                    note.meta[j].skills[k].executedUsers.push(executedUser);
                    note.meta[j].skills[k].batchno = this.flowChartTemp.batchNumber;
                    temp[i].notes[note_index].meta[j].skills[k].executedUsers = note.meta[j].skills[k].executedUsers;
                    temp[i].notes[note_index].meta[j].skills[k].verifiedUsers = note.meta[j].skills[k].verifiedUsers;
                  }
                  temptable.batchno = batchNo;
                  temptable.executedUsers = temp[i].notes[note_index].meta[j].skills[k].executedUsers;
                  temptable.verifiedUsers = temp[i].notes[note_index].meta[j].skills[k].verifiedUsers;
                this.$set(this.flowChartSkill, this.flowChartSkill.length, temptable);
              }
                }
              }
        })
        .catch(reason => {
          console.log(reason);
          this.flowChartSkill = [];
        });
      this.$set(note, "executed", true);
      this.$set(note, "executedBy", this.authCookie.userName);
      this.$set(note, "executedByOrg", this.authCookie.orgs[0].orgID);
      this.$set(note, "executedTime", new Date());
    },
    onChangeApprove(note,note_index)
    {
      console.log(note);
      console.log(note_index);
      axios
        .get(
          "/api/flowChartBatch/organizations/" +
            this.authCookie.orgs[0].orgID +
            "/datatred/" +
            this.batchID
        )
        .then(response => {
          console.log(response);
          var temp = response.data.items;
          var batchNo = '';
          var temptable = {};
          this.flowChartSkill = [];
            var i;
            for(i=0;i<temp.length;i++)
            {
              batchNo = temp[i].batchNumber;
              for(var j=0;j<temp[i].notes[note_index].meta.length;j++){
                  for(var k=0;k<temp[i].notes[note_index].meta[j].skills.length;k++){
                temptable = {};
                if (this.flowChartTemp.batchNumber == batchNo) {
                var verifiedUser = {};
                  verifiedUser.verifieduser = this.authCookie.userName;
                  note.meta[j].skills[k].verifiedUsers.push(verifiedUser);
                  note.meta[j].skills[k].batchno = this.flowChartTemp.batchNumber;
                  temp[i].notes[note_index].meta[j].skills[k].executedUsers = note.meta[j].skills[k].executedUsers;
                  temp[i].notes[note_index].meta[j].skills[k].verifiedUsers = note.meta[j].skills[k].verifiedUsers;
                }
                  temptable.batchno = batchNo;
                  temptable.executedUsers = temp[i].notes[note_index].meta[j].skills[k].executedUsers;
                  temptable.verifiedUsers = temp[i].notes[note_index].meta[j].skills[k].verifiedUsers;
                  this.$set(this.flowChartSkill, this.flowChartSkill.length, temptable);
              }
              }
            }
        })
        .catch(reason => {
          console.log(reason);
          this.flowChartSkill = [];
        });
      this.$set(note, "approved", true);
      this.$set(note, "approvedBy", this.authCookie.userName);
      this.$set(note, "approvedByOrg",this.authCookie.orgs[0].orgID);
      this.$set(note, "approvedTime", new Date());
    },
    steplock() {
      //steplock
      var steplockfound = false;
      var executedstap = false;
      var stepdeti;
      var stepdetj;
      var disarray = [];
      var i = 0;
      var totalsec = this.flowChartTemp.notes;
      if (!totalsec) {
        return;
      }
      for (i = 0; i < totalsec.length; i++) {
        var j = 0;
        var eachstep = totalsec[i].meta;
        for (j = 0; j < eachstep.length; j++) {
          //var v1 = eachstep[j].description.toString();
          var dom = document.createElement("div");
          dom.innerHTML = eachstep[j].description;
          if(!eachstep[j].description){
            return;
          }
          var imgtag = dom.getElementsByTagName("img");
          if (imgtag != undefined && imgtag.length > 0) {
            var ki;
            for (ki = 0; ki < imgtag.length; ki++) {
              //console.log(imgtag[ki]);
              var imgtagalt = imgtag[ki].getAttribute("alt");
              //console.log(imgtagalt);
              if (imgtagalt.toString() == "StepLock") {
                steplockfound = true;
                stepdeti = i;
                stepdetj = j;
                if (
                  eachstep[j].executed != undefined &&
                  eachstep[j].executed == true
                ) {
                  executedstap = true;
                }
                //console.log((i+1) +'.'+ (j+1));
                //console.log(steplockfound);
              }
            }
          }
          if (steplockfound == true) {
            if (i == stepdeti) {
              if (j > stepdetj) {
                //console.log('desable');
                //console.log((i+1) +'.'+ (j+1));
                disarray.push(i + 1 + "." + (j + 1));
              }
            } else if (i > stepdeti) {
              //console.log('desable');
              //console.log((i+1) +'.'+ (j+1));
              disarray.push(i + 1 + "." + (j + 1));
            }
          }
        }
      }

      //find div
      //console.log('disarray');
      //console.log(disarray);
      if (disarray.length > 0) {
        setTimeout(function () {
          var cuslabel = document.querySelectorAll("label.form-control-label");
          //console.log(cuslabel);
          var li;
          for (li = 0; li < cuslabel.length; li++) {
            var ji;
            for (ji = 0; ji < disarray.length; ji++) {
              if (
                cuslabel[li].innerHTML.toString() == disarray[ji].toString()
              ) {
                //console.log(cuslabel[li].innerHTML.toString());
                //console.log(cuslabel[li].parentElement.setAttribute("class","custom-disable"));
                if (executedstap == true) {
                  if (
                    cuslabel[li].parentElement.getAttribute("class") !=
                    undefined
                  ) {
                    if (
                      cuslabel[li].parentElement
                        .getAttribute("class")
                        .toString() == "custom-disable"
                    ) {
                      cuslabel[li].parentElement.removeAttribute("class");
                    }
                  }
                } else {
                  cuslabel[li].parentElement.setAttribute(
                    "class",
                    "custom-disable"
                  );
                }
              }
            }
          }
        }, 100);
      }
    },
    formatDate(strDate) {
      if (!strDate) return "";
      let date = new Date(strDate);
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return (
        date.getMonth() +
        1 +
        "/" +
        date.getDate() +
        "/" +
        date.getFullYear() +
        " " +
        strTime
      );
    },
    deleteAttr(obj) {
      if (Object.prototype.hasOwnProperty.call(obj, "uiEditable")) {
        console.log("uiEditable deleted");
        delete obj["uiEditable"];
      }
      if (obj["notes"]) {
        obj["notes"].forEach((sec) => {
          this.deleteAttr(sec);
        });
      }
      if (obj["meta"]) {
        obj["meta"].forEach((meta) => {
          this.deleteAttr(meta);
        });
      }
    },
    archiveBatch() {
      let app = this;
      this.deleteAttr(this.flowChartTemp);
      this.flowChartTemp.status1 = "archived";
      axios
        .put("/api/flowChartBatch", this.flowChartTemp)
        .then(function (response) {
          console.log(response);
          alert("Batch archived successfully!");
        })
        .catch(function (error) {
          console.log(error);
          app.flowChartTemp = [];
        });
    },
    saveFlowChart() {
      this.steplock();
      let app = this;
      this.deleteAttr(this.flowChartTemp);
      if (!this.flowChartTemp.batchID) {
        axios
          .post("/api/flowChartBatch", app.flowChartTemp)
          .then(function (response) {
            console.log(response);
            app.flowChartTemp = response.data.item;
            alert("Document Created Successfully!");
          })
          .catch(function (error) {
            console.log(error);
            app.flowChartTemp = [];
          });
      } else {
        app.flowChartTemp.updatedBy =
          app.flowChartTemp.updatedBy || this.authCookie.userName;
        axios
          .put("/api/flowChartBatch", app.flowChartTemp)
          .then(function (response) {
            console.log(response);
            app.flowChartTemp = response.data.item;
            alert("Document Saved Successfully!");
          })
          .catch(function (error) {
            console.log(error);
            app.flowChartTemp = [];
          });
      }

      console.log(this.temptag);
      var c;
      for (c = 0; c < this.temptag.length; c++) {
        axios
          .post("/api/addusagelog", this.temptag[c])
          .then(function (response) {
            console.log(response);
            //app.usagelogtagTemp = response.data.item;
            //alert("Usage Log Added Successfully!");
          })
          .catch(function (error) {
            console.log(error);
            //this.usagelogtagTemp = {};
          });
      }
      this.temptag = [];
      this.tagjson = {};
    },
    handleOverlaysClick(e) {
      if (e.target.classList.contains("ol-name")) {
        console.dir(e);
      }
    },
    handleOverlaysEdit(e) {
      if (e.target.classList.contains("ol-name")) {
        console.dir(e);

        var pValTime = prompt(
          "Attach Time needed for a particular STEP",
          e.target.innerText
        );
        if (!pValTime) return;

        e.target.innerText = pValTime;
      }
    },
    getId() {
      return uuidv4();
    },
    getCookie(name) {
      var dc = document.cookie;
      var prefix = name + "=";
      var begin = dc.indexOf("; " + prefix);
      if (begin == -1) {
        begin = dc.indexOf(prefix);
        if (begin != 0) return null;
      } else {
        begin += 2;
        var end = document.cookie.indexOf(";", begin);
        if (end == -1) {
          end = dc.length;
        }
      }
      return decodeURIComponent(
        decodeURI(dc.substring(begin + prefix.length, end))
      );
    },
    stripHTML(strVal) {
      var div = document.createElement("div");
      div.innerHTML = strVal;
      return div.textContent || div.innerText || "";
    },
    },
};
</script>
<style lang="scss">
$dark-grey: rgb(170, 170, 170);
$medium-grey: rgb(235, 233, 235);
$light-grey: rgb(248, 248, 248);
$active: #c78bd2;

//flowchartcss
rect.body.start {
  width: 120px !important;
  fill: white;
  stroke-width: 1px;
  height: 60px;
}
rect.body.end {
  width: 120px !important;
  fill: white;
  stroke-width: 1px;
  height: 60px;
}
rect.title {
  height: 55px !important;
  fill: white !important;
  stroke-width: 1px;
  width: 120px;
}
rect.body {
  width: 0px !important;
  fill: white;
  stroke-width: 1px;
  height: 40px;
}
.unselectable:nth-of-type(2) {
    display: none !important;
}
div#chart {
  width: 100% !important;
  height: 600px !important;
  overflow: scroll !important;
}
.float-buttons {
  text-align: center;
  margin-left: 43rem;
}
#svg {
    background-size: 0px !important;
    background-image: linear-gradient(90deg, #dfdfdf 1px, transparent 0), linear-gradient(180deg, #dfdfdf 1px, transparent 0), linear-gradient(90deg, #f1f1f1 1px, transparent 0), linear-gradient(180deg, #f1f1f1 1px, transparent 0);
    background-position: left -1px top -1px, left -1px top -1px, left -1px top -1px, left -1px top -1px;
    width: calc(100vh + 600px);
    margin-top: -20px;
    overflow: scroll;
    height: calc(100vh + 600px);
    position: relative;
    top: 10px;
}
#position {
    display: none !important;
    position: absolute;
    right: 4px;
}
#chart {
    position: relative;
    border: none;
}

// flowchart css


.view-flowchart-data{
  position: relative;
  z-index: 1;
}
.view-batch-data{
  margin-left: 59rem !important;
  margin-bottom: 10px;
}
.overlay-modal{
    position: fixed;
    z-index: 9998;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #1206062b;
}
#planet-chart{
  max-width: 500px;
  margin: 1.75rem auto;
}
.btn-default{
    color: #fff;
    background-color: #172b4d;
    border-color: #172b4d;
}
#editorControlListLeftFlowchart {
  height: calc(100vh - 200px);
  overflow: scroll;
  width: 65px;
}
.btn{
  width: 130px;
}
.user-audit {
  margin-left: 5px;
  color: darkgray !important;
}
.editControlParent {
  width: 50px;
  position: fixed;
  flex-basis: auto;
}
.editRightParent {
  width: 100px;
  position: fixed;
  right: 45px;
  bottom: 50px;
  flex-basis: auto;
  margin-right: 10px;
}
.editorNewBatch {
  width: calc(100% - 180px);
  margin-left: 70px;
  margin-right: 120px;
  flex-basis: auto;
}
.time-input::after {
  content: url(/img/pptx/time-small.png);
}

.u-photo {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  width: 45px;
}
.leftIcon {
  font-size: 10px;
  position: relative;
  top: -10px;
}
.editRightbtn {
  margin-bottom: 10px;
}
.sec-toggle {
  background-color: #0001;
  color: white;
  cursor: pointer;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
}
.sec-toggle.accordion:after {
  content: "\002B";
  color: white;
  font-weight: bold;
  float: right;
  margin-left: 5px;
}
.card.shadow3 {
  border: 1px solid #dadce0 !important;
  padding: 6px;
  // margin-top: 20px;
}
.flowChartbatch-header {
  font-weight: 700;
  // top: -100px;
  // position: relative;
  // left: 110px;
  text-align: center;
  font-size: 20px;
  // background-color: #ffff;
  // margin-right: 95px;
  // padding-left: 10px;
  // padding-top: 5px;
  // padding-bottom: 5px;
  // border-radius: 0.375rem;
  width: calc(100% - 270px);
}
.custom-disable {
  opacity: 0.4;
}
.custom-disable input[type="checkbox"] {
  display: none;
}
input[type="checkbox"] {
  display: inline;
}
.custom-disable button {
  display: none;
}
button {
  display: inline;
}
.btn-default1 {
  margin-left: 5px;
  color: #fff;
  background-color: #172b4d;
  border-color: #172b4d;
  // box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
}
.ag-theme-balham .ag-cell.ag-cell-inline-editing {
  height: inherit;
  border: none;
  padding: 0 10px;
}

.ag-theme-balham .ag-cell-data-changed {
  background-color: $medium-grey !important;
}
.ag-theme-balham {
  box-shadow: 0 0 5px $medium-grey;
}
.ag-theme-balham .ag-cell {
  align-items: center;
}
.ag-cell.ag-cell-not-inline-editing.ag-cell-with-height.ag-cell-value span {
  height: 20px;
}
.ag-theme-balham .ag-cell {
  line-height: 15px !important;
  padding-left: 12px;
  padding-right: 12px;
  border: 1px solid transparent;
  padding-left: 11px;
  padding-right: 11px;
  display: -webkit-inline-box !important;
}
select.bg-option {
  display: none;
}
h1.mt-5.text-2xl {
  display: block;
}
.mb-8 {
  margin-bottom: 2rem !important;
  margin-right: 2rem !important;
}
.relative {
  position: relative;
}
.mt-5 {
  margin-top: 1.25rem;
}
.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}
.float-right {
  float: right;
}
.flowchart-sticky {
  width: 35%;
  margin: auto;
  padding-top: 3rem;
  padding-bottom: 7rem;
  padding-left: 2rem;
  padding-right: 2rem;
}
.note {
  width: 280px;
  min-height: 300px;
  box-shadow: 5px 5px 10px -2px rgba(33, 33, 33, 0.3);
  /* transform: skew(-1deg, 1deg); */
  transition: transform 0.15s;
  z-index: 1;
}

.note textarea.cnt {
  min-height: 100px;
  outline: none;
  border: none;
  font-size: 1rem;
  line-height: 1.75rem;
}
.p-4 {
  padding: 1rem;
}
.w-full {
  width: 100%;
}
.p-1 {
  padding: 0.25rem;
}
.resize-y {
  resize: vertical;
}
.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: center;
}
.bg-option {
  color: #fff;
  background-color: #172b4d;
  border-color: #172b4d;
  margin-right: 2rem;
  height: 1.75rem;
  border-radius: 0.25rem;
}

.button.remove {
  top: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #e01c12;
  border-color: #b30000;
  font-weight: bolder;
  font-family: Helvetica, Arial, sans-serif;
}

.button.remove:hover {
  background-color: #ef0005;
  cursor: pointer;
}

.floating__btn {
  background-color: #172b4d !important;
  border-radius: 50%;
  border: none !important;
//   box-shadow: 0 0 4px rgb(0 0 0 / 14%), 0 4px 8px rgb(0 0 0 / 28%);
  z-index: 998;
  outline: none;
}
.float-left {
  float: left;
}
.w-16 {
  width: 4rem;
}
.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.bottom-0 {
  bottom: 0;
}
.right-0 {
  right: 0;
}
.fixed {
  position: fixed;
}
.m-4 {
  margin: 1rem;
}
.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}
.h-16 {
  height: 4rem;
}
.justify-center {
  justify-content: center;
}
.items-center {
  align-items: center;
}
.flex {
  display: flex;
}
.cursor-pointer {
  cursor: pointer;
}
.absolute {
  position: absolute;
}
.mt-0 {
  min-height: 25%;
}
.arrow-right {
  content: " ";
  position: absolute;
  right: -30px;
  top: 60px;
  border-top: 15px solid transparent;
  border-right: none;
  border-left: 15px solid black;
  border-bottom: 15px solid transparent;
}
.arrow-left {
  content: " ";
  position: absolute;
  left: -30px;
  top: 60px;
  border-top: 15px solid transparent;
  border-right: 15px solid black;
  border-left: none;
  border-bottom: 15px solid transparent;
}
.arrow-bottom {
  content: " ";
  position: absolute;
  right: 130px;
  bottom: -30px;
  border-top: 15px solid black;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-bottom: none;
}


// grid layout css

.layoutJSON {

    -moz-columns: 120px;
    -webkit-columns: 120px;
    columns: 120px;
}
.remove {
    position: absolute;
    right: 2px;
    top: 0;
    cursor: pointer;
}

.vue-grid-layout {
    background: #eee;
}

.vue-grid-item:not(.vue-grid-placeholder) {
    background: #ccc;
    border: 1px solid black;
}

.vue-grid-item .resizing {
    opacity: 0.9;
}

.vue-grid-item .static {
    background: #cce;
}

.vue-grid-item .text {
    font-size: 24px;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
    width: 100%;
}

.vue-grid-item .no-drag {
    height: 100%;
    width: 100%;
}

.vue-grid-item .minMax {
    font-size: 12px;
}

.vue-grid-item .add {
    cursor: pointer;
}

.vue-draggable-handle {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>") no-repeat;
    background-position: bottom right;
    padding: 0 8px 8px 0;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: pointer;
}

@import "ag-grid-community/src/styles/ag-grid.scss";
@import "ag-grid-community/src/styles/ag-theme-balham/sass/ag-theme-balham.scss";
</style>

<template>
  <div>
    <h1 class="text-2xl" :style="{ fontFamily: fontFamily }">
      Shared Notes
    </h1>
    <div class="sharedNotes">
      <draggable :list="notes" :animation="200" group="notes">
        <div
          class="
            note
            block
            float-left
            mt-0
            ml-0
            mr-8
            mb-8
            p-4
            relative
            cursor-move
          "
          v-for="(note, index) in notes"
          :key="index"
          :style="note.color"
          
        >
          <a
            class="
              button
              remove
              flex
              items-center
              justify-center
              h-8
              absolute
              text-white
              w-8
            "
            @click="removeNote(index)"
            >x</a
          >
          <div class="note_cnt text-xl">
            <textarea
              class="cnt bg-transparent p-1 resize-y w-full outline-none"
              placeholder="Enter note description here"
              v-model="note.description"
              :style="{ fontFamily: fontFamily }"
            ></textarea>
          </div>
          <div class="timestamp">
            <input type="checkbox" id="checkbox" v-model="note.executed" :disabled="note.executed" @click="executeDate(note)"/> 
            <span style="font-size: 12px;" v-if="note.executed">{{note.executedBy + " - on " + formatDate(note.executedTime) }}</span>
          </div>
        </div>
      </draggable>
    </div>
    <button
      class="
        floating__btn
        cursor-pointer
        flex
        text-6xl
        items-center
        justify-center
        h-16
        right-0
        fixed
        bottom-0
        position
        text-white
        w-16
      "
      @click="addNote()"
    >
      +
    </button>
    <button class="btn btn-info btn-save" @click="saveSharedNotes()">SAVE</button>
  </div>
</template>

<script>
import axios from "../../utils/axiosinstance";
import draggable  from "vuedraggable";

export default {
  name: "sharedNotes",
  props: ["sharedNotesid"],
  components: { draggable },
  mounted() {
    this.getSharedNotesList();
    // this.getColor();
  },
  methods: {
    notifyUser(notesNotification){
      console.log(notesNotification);
      for(var i = 0; i < notesNotification.length; i++){
      var notification = {
          objectId: notesNotification[i].sharedNotesID,
          type: "sharedNotes",
          message:  " has created  sharednotes " + " with descrption " + notesNotification[i].description,
      };
      }
      this.$root.$emit('listenNotification', notification);
      console.log(notification);
    },
    addNote() {
      console.log("notes:" + this.notes);
      this.notes.push({
        description: "",
        createdAt: new Date(),
        color: this.getColor(),
      });
    },
    removeNote(index) {
      this.notes.splice(index, 1);
    },
    getColor() {
      const colors = ["#ff000066", "#ff7f007a", "#ffff004f", "#00ff0059", "#0000ff54", "#4b008263", "#9400d366"];
      const color = colors[Math.floor(Math.random() * colors.length)];
      return {
        backgroundColor: color,
      };
    },
    formatDate(strDate) {
      if (!strDate) return "";
      let date = new Date(strDate);
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return (
        date.getMonth() +
        1 +
        "/" +
        date.getDate() +
        "/" +
        date.getFullYear() +
        " " +
        strTime
      );
    },
    executeDate(note){
      this.$set(note, "executed", true);
      this.$set(note, "executedBy", this.authCookie.userName);
      this.$set(note, "executedTime", new Date());
      console.log(name);
    },
    saveSharedNotes() {
      let that = this;
      axios
        .post("/api/sharedNotes", that.notes)
        .then(function (response) {
          console.log(response);
          that.notes = [];
          that.notes = response.data.items;
          alert("SharedNotes Created Successfully!");
            that.notifyUser(that.notes);
            window.location = "/sharedNotes";
        })
        .catch(function (error) {
          console.log(error);
          that.notes = [];
        });
    },
    getSharedNotesList() {
      let userID = this.authCookie.userID;
      if(this.authCookie.departments && this.authCookie.departments[0]){
        var sharedNotesListURL;
      sharedNotesListURL = "/api/sharedNotes?userID=" + userID;
      axios
        .get(sharedNotesListURL)
        .then((response) => {
          console.log(response);
          if (
            response &&
            response.data &&
            response.data.items &&
            response.data.items.length > 0
          ) {
            this.notes = response.data.items;
          }

          console.log(response.data.items);
          console.log(response.data.count);
        })
        .catch((reason) => {
          console.log(reason);
          this.notes = [];
        });
      }
      else{
        sharedNotesListURL = "/api/sharedNotes?userID=" + userID;
      axios
        .get(sharedNotesListURL)
        .then((response) => {
          console.log(response);
          if (
            response &&
            response.data &&
            response.data.items &&
            response.data.items.length > 0
          ) {
            this.notes = response.data.items;
          }

          console.log(response.data.items);
          console.log(response.data.count);
        })
        .catch((reason) => {
          console.log(reason);
          this.notes = [];
        });
      }
    },
    getSharedNotes() {
      let userID = this.authCookie.userID;
      var sharedNotesID = "/api/sharedNotes?userID=" + userID;
      axios
        .get(
          "/api/organizations/" +
            this.authCookie.orgs[0].userID +
            "/sharedNotes/" +
            sharedNotesID
        )
        .then((response) => {
          console.log(response);
          // this.sharedNotesTemp = response.data.item;
          this.notes = response.data.items;
          console.log(response.data.item);
        })
        .catch((reason) => {
          console.log(reason);
          this.notes = [];
        });
    },
    getCookie(name) {
      var dc = document.cookie;
      var prefix = name + "=";
      var begin = dc.indexOf("; " + prefix);
      if (begin == -1) {
        begin = dc.indexOf(prefix);
        if (begin != 0) return null;
      } else {
        begin += 2;
        var end = document.cookie.indexOf(";", begin);
        if (end == -1) {
          end = dc.length;
        }
      }
      return decodeURIComponent(
        decodeURI(dc.substring(begin + prefix.length, end))
      );
    },
  },
  created() {
    let authCookieObj = this.getCookie("auth-token");
    if (authCookieObj) {
      // this.authCookie = JSON.parse(authCookieObj.substring(2));
      let authObj;
      if (authCookieObj.startsWith("j:")) {
        authObj = JSON.parse(
          decodeURIComponent(
            this.getCookie("auth-token").substring(2).split(";")[0]
          )
        );
      } else {
        authObj = JSON.parse(decodeURIComponent(authCookieObj.split(";")[0]));
      }
      this.authCookie = authObj;
    }
  },
  data() {
    return {
      authCookie: null,
      notes: [
        {
          description: "",
          createdAt: new Date(),
          color: this.getColor(),
        },
      ],
      fontFamily: "Cedarville Cursive",
      date: new Date().toISOString().slice(0,10) ,
    };
  },
};
</script>

<style scoped>
::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.7);
}
:placeholder {
  color: rgba(0, 0, 0, 0.7);
}
select.bg-option {
  display: none;
}
h1.mt-5.text-2xl {
  display: block;
}
.mb-8 {
  margin-bottom: 2rem !important;
  margin-right: 2rem !important;
}
.relative {
  position: relative;
}
.mt-5 {
  margin-top: 1.25rem;
}
.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}
.float-right {
  float: right;
}
.sharedNotes {
  padding-top: 3rem;
  padding-bottom: 7rem;
  padding-left: 2rem;
  padding-right: 2rem;
}
.note {
  width: 280px;
  min-height: 300px;
  box-shadow: 5px 5px 10px -2px rgba(33, 33, 33, 0.3);
  /* transform: skew(-1deg, 1deg); */
  transition: transform 0.15s;
  z-index: 1;
}

.note textarea.cnt {
  min-height: 100px;
  outline: none;
  border: none;
  font-size: 1rem;
  line-height: 1.75rem;
}
.p-4 {
  padding: 1rem;
}
.w-full {
  width: 100%;
}
.p-1 {
  padding: 0.25rem;
}
.resize-y {
  resize: vertical;
}
.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: center;
}
.bg-option {
  color: #fff;
  background-color: #172b4d;
  border-color: #172b4d;
  margin-right: 2rem;
  height: 1.75rem;
  border-radius: 0.25rem;
}

.button.remove {
  top: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #e01c12;
  border-color: #b30000;
  font-weight: bolder;
  font-family: Helvetica, Arial, sans-serif;
}

.button.remove:hover {
  background-color: #ef0005;
  cursor: pointer;
}

.floating__btn {
  background-color: #172b4d !important;
  border-radius: 50%;
  border: none !important;
  box-shadow: 0 0 4px rgb(0 0 0 / 14%), 0 4px 8px rgb(0 0 0 / 28%);
  z-index: 998;
  outline: none;
  margin:10px;
}
.float-left {
  float: left;
}
.w-16 {
  width: 3.5rem;
  margin-top: -100px;
}
.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}
.bottom-0 {
  bottom: 0;
}
.right-0 {
  right: 0;
}
/* .fixed {
  position: fixed;
}
.m-4 {
  margin: 1rem;
} */
.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}
.h-16 {
  height: 3.5rem;
  width: 3.5rem
}
.justify-center {
  justify-content: center;
}
.items-center {
  align-items: center;
}
.flex {
  display: flex;
}
.cursor-pointer {
  cursor: pointer;
}
.absolute {
  position: absolute;
}
.mt-0 {
  /* margin-top: -70px !important; */
  min-height: 25%;
}
.date-calender{
  margin-top: 10px;
  margin-left: 37px;
}
.btn-save{
  background-color: #172b4d !important;
  color: #fff !important;
}
</style>

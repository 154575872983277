<template>
  <div>
    <div class="col-md-12">
      <div class="row planner-header">
        <div class="dropdown">
          <select
            class="user-dropdown"
            @change="onChange($event)"
            v-model="selectedUser"
          >
            <option
              v-for="option in userList"
              :key="option.userID"
              :value="option.userID"
            >
              {{ option.userName }}
            </option>
          </select>
        </div>
        <select v-model="selectedView" class="view-select">
          <option
            v-for="view in viewOptions"
            :key="view.value"
            :value="view.value"
          >
            {{ view.title }}
          </option>
          <span
            class="
              dropdown-icon
              toastui-calendar-icon toastui-calendar-ic-dropdown-arrow
            "
          ></span>
        </select>

        <div class="buttons">
          <button type="button is-rounded" @click="onClickTodayButton">
            Today
          </button>
          <button type="button is-rounded" @click="onClickMoveButton(-1)">
            Prev
          </button>
          <button type="button is-rounded" @click="onClickMoveButton(1)">
            Next
          </button>
        </div>
        <span class="date-range">{{ dateRangeText }}</span>
      </div>
    </div>
    <div id="calendar" style="height: 800px"></div>
  </div>
</template>

<script>
import axios from "../../utils/axiosinstance";
// import { v4 as uuidv4 } from "uuid";
import Calendar from "@toast-ui/calendar";
import "@toast-ui/calendar/dist/toastui-calendar.min.css";
import "tui-date-picker/dist/tui-date-picker.min.css";
import "tui-time-picker/dist/tui-time-picker.min.css";
export default {
  name: "planner",
  props: ["userID"],
  watch: {
    selectedView(newView) {
      this.calendar.changeView(newView);
      this.setDateRangeText();
    },
  },
  mounted() {
    this.getOrgnaizationUser();
    this.getCalender();
    this.getPlannerList();
    this.fetchLoginUserEvents();
  },
  created() {
    let authCookieObj = this.getCookie("auth-token");
    if (authCookieObj) {
      let authObj;
      if (authCookieObj.startsWith("j:")) {
        authObj = JSON.parse(
          decodeURIComponent(
            this.getCookie("auth-token").substring(2).split(";")[0]
          )
        );
      } else {
        authObj = JSON.parse(decodeURIComponent(authCookieObj.split(";")[0]));
      }
      this.authCookie = authObj;
    }
  },
  methods: {
    getCalender() {
      this.calendar = new Calendar("#calendar", {
        defaultView: "week",
        useFormPopup: true,
        useDetailPopup: true,
        week: {
          taskView: ['task'],
          eventView: ['time'],
          showNowIndicator: false,
        },
        template: {
          titlePlaceholder() {
            return 'Task Description';
          },
          locationPlaceholder() {
            return 'Assigned by';
          },
          popupIsAllday(){
            return 'isTask';
          },
          popupStateBusy() {
            return 'Progress';
            // return '>30% progress';
          },
          popupStateFree() {
            return 'Done';
          },
          time(event) {
            const { start, end, title } = event;
            return `<span style="color: white;">${start}~${end} ${title}</span>`;
          },
          allday(event) {
            return `<span style="color: gray;">${event.title}</span>`;
          },
          task(event) {
          return `<div><input type="checkbox"><span>Attendance<span>${event.title}</div>`;
        },
        },
      });
      this.setDateRangeText();
      this.calendar.on("clickEvent", (eventObj) => {
        console.log(eventObj);
      });
      this.calendar.on("beforeCreateEvent", (eventObj) => {
        console.log(eventObj);
        this.eventTemp = eventObj;
        this.eventTemp.selectedUser = this.selectedUser;
        this.savePlanner();
      });
      this.calendar.on("beforeUpdateEvent", (updateData) => {
        this.updatePlanner(updateData);
      });
      this.calendar.on("beforeDeleteEvent", (eventObj) => {
        this.deletePlanner(eventObj);
      });
    },
    savePlanner() {
      let that = this;
      axios
        .post("/api/planner", that.eventTemp)
        .then(function (response) {
          console.log(response);
          that.eventList.push(response.data.item);
          that.calendar.clear();
          that.calendar.createEvents(that.eventList);
          // window.location = "/planner";
        })
        .catch(function (error) {
          console.log(error);
          that.eventTemp = {};
        });
    },
    getOrgnaizationUser() {
      var userListURL =
        "/api/user/organizations/" + this.authCookie.orgs[0].orgID;
      axios
        .get(userListURL)
        .then((response) => {
          console.log(response);
          this.userList = response.data.items;
          this.selectedUser = this.authCookie.userID;
          console.log(response.data.items);
        })
        .catch((reason) => {
          console.log(reason);
          this.userList = [];
        });
    },
    getPlannerList() {
      let userID = this.authCookie.userID;
      var plannerListURL = "/api/planner?userID=" + userID;
      axios
        .get(plannerListURL)
        .then((response) => {
          console.log(response);
          this.eventList = response.data.items;
          this.calendar.createEvents(this.eventList);
          console.log(response.data.items);
          console.log(response.data.count);
        })
        .catch((reason) => {
          console.log(reason);
          this.eventTemp = [];
        });
    },
    onChange(event) {
      console.log(event.target.value, this.selectedUser);
      this.fetchUserEvents(this.selectedUser);
    },
    fetchUserEvents(userID) {
      var userListURL = "/api/planner?userID=" + userID;
      axios
        .get(userListURL)
        .then((response) => {
          console.log(response);
          this.eventList = response.data.items;
          this.calendar.clear();
          this.calendar.createEvents(this.eventList);
          console.log(response.data.items);
          console.log(response.data.count);
        })
        .catch((reason) => {
          console.log(reason);
          this.eventTemp = [];
        });
    },
    fetchLoginUserEvents() {
      this.fetchUserEvents(this.authCookie.userID);
    },
    setDateRangeText() {
      const date = this.calendar.getDate();
      const start = this.calendar.getDateRangeStart();
      const end = this.calendar.getDateRangeEnd();
      const startYear = start.getFullYear();
      const endYear = end.getFullYear();
      switch (this.selectedView) {
        case "month":
          this.dateRangeText = `${date.getFullYear()}-${date.getMonth() + 1}`;
          return;
        case "day":
          this.dateRangeText = `${date.getFullYear()}-${
            date.getMonth() + 1
          }-${date.getDate()}`;
          return;
        default:
          this.dateRangeText = `${startYear}-${
            start.getMonth() + 1
          }-${start.getDate()} ~ ${endYear}-${
            end.getMonth() + 1
          }-${end.getDate()}`;
      }
    },
    onClickTodayButton() {
      this.calendar.today();
      this.setDateRangeText();
    },
    onClickMoveButton(offset) {
      this.calendar.move(offset);
      this.setDateRangeText();
    },
    updatePlanner(updateData) {
      let that = this;
      that.eventTemp = updateData.changes;
      that.eventTemp.updatedBy = that.eventTemp.updatedBy || that.authCookie.userName;
      that.eventTemp.id = updateData.event.id;
      that.eventTemp.selectedUser = that.selectedUser;
      axios
        .put("/api/planner", that.eventTemp)
        .then(function (response) {
          console.log(response);
          that.calendar.updateEvent(response.data.item.id, response.data.item.calendarId, updateData.changes);
          // window.location = "/planner";
        })
        .catch(function (error) {
          console.log(error);
          that.eventTemp = {};
        });
    },
    deletePlanner(eventObj){
      let that = this;
      that.eventTemp = eventObj;
      that.eventTemp.id = eventObj.id;
      that.eventTemp.selectedUser = that.selectedUser;
      // this.calendar.deleteEvent(eventObj.id, eventObj.calendarId);
      // this.calendar.splice(eventObj.id, 1);
      axios
        .delete("/api/planner/"+ eventObj.id)
        .then(function (response) {
          console.log(response);
          that.calendar.deleteEvent(eventObj.id, eventObj.calendarId);
        })
        .catch(function (error) {
          console.log(error);
          that.eventTemp = {};
        });
    },
    formatDate(strDate) {
      if (!strDate) return "";
      let date = new Date(strDate);
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return (
        date.getMonth() +
        1 +
        "/" +
        date.getDate() +
        "/" +
        date.getFullYear() +
        " " +
        strTime
      );
    },
    getCookie(name) {
      var dc = document.cookie;
      var prefix = name + "=";
      var begin = dc.indexOf("; " + prefix);
      if (begin == -1) {
        begin = dc.indexOf(prefix);
        if (begin != 0) return null;
      } else {
        begin += 2;
        var end = document.cookie.indexOf(";", begin);
        if (end == -1) {
          end = dc.length;
        }
      }
      return decodeURIComponent(
        decodeURI(dc.substring(begin + prefix.length, end))
      );
    },
  },
  data() {
    return {
      selectedUser: "",
      authCookie: null,
      calendar: Calendar,
      eventTemp: [],
      eventList: [],
      userList: [],
      newComments: [],
      selectedView: "week",
      viewOptions: [
        {
          title: "Monthly",
          value: "month",
        },
        {
          title: "Weekly",
          value: "week",
        },
        {
          title: "Daily",
          value: "day",
        },
      ],
      dateRangeText: "",
    };
  },
};
</script>

<style>
.toastui-calendar-popup-section-item.toastui-calendar-popup-section-allday {
  display: none;
}
.toastui-calendar-state-section .toastui-calendar-content {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: auto !important;
}
.toastui-calendar-popup-button .toastui-calendar-event-state {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* width:  -1px !important; */
}
.toastui-calendar-dropdown-section.toastui-calendar-state-section {
  width: 110px !important;
}
.toastui-calendar-panel.toastui-calendar-task {
  width: 100%;
  height: 172px !important;
  border-bottom: 1px solid #dedadafc;
}
.toastui-calendar-timegrid-time {
  display: none;
}
.planner-header {
  margin: 25px;
  display: flex;
}
.buttons {
  display: inline-block;
  margin-left: 10px;
}

.buttons > button {
  margin-right: 5px;
  background-color: #fff;
  /* border-color: #dbdbdb; */
  border-radius: 9999px;
  border-width: 1px;
  color: #363636;
  cursor: pointer;
  justify-content: center;
  padding-bottom: calc(0.5em - 1px);
  padding-left: 1em;
  padding-right: 1em;
  padding-top: calc(0.5em - 1px);
  text-align: center;
  white-space: nowrap;
}

.buttons > button:last-child {
  margin-right: 0;
}

.date-range {
  margin-left: 1rem;
  font-size: 1.25rem;
  padding: 5px;
}
.view-select {
  border-radius: 9999px;
  padding-left: calc(1em + 0.25em);
  padding-right: calc(1em + 0.25em);
}
.user-dropdown {
  border-radius: 9999px;
  text-align: center;
  padding: 10px;
  margin-right: 15px;
}
</style>
<template>
  <iframe src="/wphome" width="100%" height="100%" frameborder="0"> </iframe>
</template>
<script>
export default {
  name: "home",
  data() {
    return {};
  },
};
</script>
<style>
</style>

<template>
  <div>
    <div class="row justify-content-center">
      <div class="card bg-secondary shadow border-0">
        <div>
          <form role="form" id="change-pwd-form">
            <label>Old Password</label>
            <base-input
              class="input-group-alternative mb-3"
              type="password"
              required="true"
              :valid="oldPasswordValid"
              addon-left-icon="ni ni-hat-3"
              v-model="oldPassword"
              :error="oldPasswordErr"
              @keydown="validatePwds($event, 0)"
              @blur="validatePwds($event, 0)"
            ></base-input>
            <label>New Password</label>
            <base-input
              class="input-group-alternative"
              type="password"
              required="true"
              :valid="newPasswordValid"
              addon-left-icon="ni ni-lock-circle-open"
              v-model="newPassword"
              :error="newPasswordErr"
              @keydown="validatePwds($event, 1)"
              @blur="validatePwds($event, 1)"
            ></base-input>
            <label>Confirm New Password</label>
            <base-input
              class="input-group-alternative"
              type="password"
              required="true"
              :valid="confirmNewPasswordValid"
              addon-left-icon="ni ni-lock-circle-open"
              v-model="confirmNewPassword"
              :error="confirmNewPasswordErr"
              @keydown="validatePwds($event, 2)"
              @blur="validatePwds($event, 2)"
            ></base-input>

            <div class="text-center">
              <base-button
                type="primary"
                class="my-4"
                v-on:click="handleChangePassword"
                >Change Password</base-button
              >
            </div>
          </form>
        </div>
      </div>
      <!-- <div class="row mt-3" style="display: inline">
        <div class="col-12" style="margin: 15px">
          <a @click="launchRegister" class="text-light">
            <small>Create new account</small>
          </a>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
/* eslint-disable no-console */
import axios from "../utils/axiosinstance";
export default {
  name: "change-password",
  data() {
    return {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      oldPasswordErr: "",
      newPasswordErr: "",
      confirmNewPasswordErr: "",
      oldPasswordValid: true,
      newPasswordValid: true,
      confirmNewPasswordValid: true,
    };
  },
  methods: {
    validatePwds() {
      // console.log(e, pwdField);
      var inputPwdList = this.getPwdInputs();
      this.validateOldPwd(inputPwdList[0]);
      this.validateNewPwd(inputPwdList[1], inputPwdList[0]);
      this.validateConfirmNewPwd(inputPwdList[2], inputPwdList[1]);
    },
    validateOldPwd(el) {
      if (el.value.trim()) {
        this.oldPasswordValid = true;
        this.oldPasswordErr = "";
      } else {
        this.oldPasswordValid = false;
        this.oldPasswordErr = "Password can't be empty";
      }
    },
    validateNewPwd(elNew, elOld) {
      // https://stackoverflow.com/questions/19605150/regex-for-password-must-contain-at-least-eight-characters-at-least-one-number-a

      var regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/;

      // console.log('fail: ' + regex.test(fail1))
      if (elNew.value == elOld.value) {
        this.newPasswordValid = false;
        this.newPasswordErr = "New and Old password must be different";
        return;
      }
      if (regex.test(elNew.value)) {
        this.newPasswordValid = true;
        this.newPasswordErr = "";
      } else {
        this.newPasswordValid = false;
        this.newPasswordErr =
          "Password must have -> at least 8 characters -> at least 1 numeric character -> at least 1 lowercase letter -> at least 1 uppercase letter -> at least 1 special character -> space not allowed";
      }
    },
    validateConfirmNewPwd(elCon, elNew) {
      if (elCon.value == elNew.value) {
        this.confirmNewPasswordValid = true;
        this.confirmNewPasswordErr = "";
      } else {
        this.confirmNewPasswordValid = false;
        this.confirmNewPasswordErr =
          "New Password and Confirm New Password don't match";
      }
    },
    handleChangePassword() {
      if (
        this.oldPasswordValid &&
        this.newPasswordValid &&
        this.confirmNewPasswordValid
      ) {
        console.log("Pass");

        let app = this;
        axios
          .post("/api/changePassword", {
            oldPID: this.oldPassword,
            newPID: this.newPassword,
            confirmPID: this.confirmNewPassword,
          })
          .then(function () {
            // console.log(response);
            app.$router.push("/dashboard");
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    getPwdInputs() {
      var ipDivs = document
        .querySelector("#change-pwd-form")
        .querySelectorAll(".form-group");
      var oldPwdIp = null,
        newPwdIp = null,
        confirmPwdIp = null;
      if (ipDivs.length >= 3) {
        oldPwdIp = ipDivs[0].querySelector("input");
        newPwdIp = ipDivs[1].querySelector("input");
        confirmPwdIp = ipDivs[2].querySelector("input");
      }
      return [oldPwdIp, newPwdIp, confirmPwdIp];
    },
  },
};
</script>
<style>
</style>

import { render, staticRenderFns } from "./sharedNotes.vue?vue&type=template&id=46b94c8a&scoped=true"
import script from "./sharedNotes.vue?vue&type=script&lang=js"
export * from "./sharedNotes.vue?vue&type=script&lang=js"
import style0 from "./sharedNotes.vue?vue&type=style&index=0&id=46b94c8a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46b94c8a",
  null
  
)

export default component.exports